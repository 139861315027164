<template>
    <r-e-dialog title="编辑" show-footer :visible.sync="dialogVisible" width="600px" top="5vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" label-position="top" size="small" :model="formPublish"
                 :rules="rules">
            <div class="flex justify-between">
                <el-form-item label="资产名称" prop="assetName">
                    <el-input v-model="formPublish.assetName" placeholder="请输入资产名称" style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="采购申请&采购合同号" prop="purchaseContractNo">
                    <el-input v-model="formPublish.purchaseContractNo" placeholder="请输入采购申请&采购合同号"
                              style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="规格型号" prop="model">
                    <el-input v-model="formPublish.model" placeholder="请输入规格型号" style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="合同日期" prop="contractDate">
                    <el-date-picker v-model="formPublish.contractDate" type="date" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd" placeholder="请选择入库日期" style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="产品单价" prop="price">
                    <el-input v-model="formPublish.price" placeholder="请输入产品单价" style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="供应商" prop="supplierUuid">
                    <el-select v-model="formPublish.supplierUuid" filterable placeholder="请选择供应商" style="width: 250px;">
                        <el-option v-for="(item,index) in supplierList" :key="index" :label="item.companyName"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="采购数量" prop="number">
                    <el-input v-model="formPublish.number" placeholder="请输入采购数量" style="width: 250px;"/>
                </el-form-item>
                <el-form-item label="单位" prop="unit">
                    <el-input v-model="formPublish.unit" placeholder="请输入单位" style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="合同批次" prop="contractBatch">
                    <el-input v-model="formPublish.contractBatch" placeholder="请输入合同批次" style="width: 250px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-between">
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" v-model="formPublish.remark" :rows="3" placeholder="请输入内容"
                              style="width: 550px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {getSupplierListAll} from "@/api/supplier";
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {MessageSuccess, MessageError} from "@custom/message";
import {assetsUpdate} from "@/api/assets";

export default {
    name: "layer-edit-assets-warehousing",
    data() {
        let isNumber = (rule, value, callback) => {
            if (isNaN(Number(value))) {
                return callback(new Error('请输入数字值'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            formPublish: {
                uuid: null,
                assetName: null,
                purchaseContractNo: null,
                model: null,
                contractDate: null,
                number: null,
                unit: null,
                price: null,
                supplierUuid: null,
                contractBatch: null,
                remark: null,
            },
            rules: {
                assetName: [{required: true, message: '请输入资产名称', trigger: 'blur'}],
                model: [{required: true, message: '请输入规格型号', trigger: 'blur'}],
                contractDate: [],
                number: [
                    {required: true, message: '请输入采购数量', trigger: 'blur'},
                    { validator: isNumber, trigger: 'blur' },
                ],
                unit: [{required: true, message: '请输入单位', trigger: 'blur'}],
                price: [
                    {required: true, message: '请输入产品单价', trigger: 'blur'},
                    { validator: isNumber, trigger: 'blur' },
                ],
                supplierUuid: [{required: true, message: '请选择供应商', trigger: 'change'}],
                contractBatch: [],
            },
            supplierList: [],
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    components: {},
    methods: {
        async openDialog(data) {
            const {
                uuid, assetName, purchaseContractNo, model, contractDate, price, supplierUuid, number, unit,
                contractBatch, remark,
            } = data;
            let that = this;
            that.formPublish.uuid = uuid;
            that.formPublish.assetName = assetName;
            that.formPublish.purchaseContractNo = purchaseContractNo;
            that.formPublish.model = model;
            that.formPublish.contractDate = new Date(contractDate);
            that.formPublish.price = (ParseFloatFormat(price)).toFixed(2);
            that.formPublish.supplierUuid = supplierUuid;
            that.formPublish.number = number;
            that.formPublish.unit = unit;
            that.formPublish.contractBatch = contractBatch;
            that.formPublish.remark = remark;
            that.dialogVisible = true;
        },
        clickSubmit() {
            const that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    let {
                        uuid, assetName, purchaseContractNo, model, contractDate, price, supplierUuid, number, unit,
                        contractBatch, remark,
                    } = that.formPublish;
                    if (isNaN(Number(number))) {
                        MessageError("采购数量请输入数字");
                        return;
                    }
                    if (isNaN(Number(price))) {
                        MessageError("金额请输入数字");
                        return;
                    }
                    price = ParseIntFormat(price);
                    number = Number(number);
                    const data = {
                        uuid, assetName, purchaseContractNo, model, contractDate, price, supplierUuid, number, unit,
                        contractBatch, remark,
                    }
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    assetsUpdate(data).then(res => {
                        const {msg} = res;
                        MessageSuccess(msg);
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        clickCancel() {
            this.formPublish.uuid = null;
            this.$refs["formPublish"].resetFields();
            this.$emit("handleSearch", false);
            this.dialogVisible = false;
        },
    },
    async mounted() {
        const {list} = await getSupplierListAll();
        this.supplierList = list;
    },
}
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__label {
    line-height: 0 !important;
    padding: 0 0 5px 0 !important;
}

.title {
    position: relative;
    padding-left: 10px;
    font-size: 18px;

    &::before {
        width: 5px;
        height: 100%;
        content: '';
        background-color: #F5A623;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>